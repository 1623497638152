'use client'
import {
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
} from './components/clientComponents/Accordion'
import { IInputOtp } from './components/clientComponents/InputOtp/IInputOtp'
import { Checkbox } from './components/clientComponents/Checkbox/Checkbox'
import { ICheckbox } from './components/clientComponents/Checkbox/ICheckbox'
import { Input, IInput } from './components/clientComponents/Input/'
import { VisuallyHidden } from '@reach/visually-hidden'
import {
	RadioRoot,
	RadioItem,
	BaseRadioItem,
	RadioIndicator,
} from './components/clientComponents/RadioGroup/'
import {
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
	DialogTitle,
	DialogTrigger,
} from './components/clientComponents/Modal'
import {
	InputFileItem,
	IInputFile,
} from './components/clientComponents/InputFile'
import {
	ISelect,
	SelectItem,
	Select,
} from './components/clientComponents/Select'
import { Icon, IIcon } from './components/clientComponents/Icon'
import {
	DropDownMenuRoot,
	DropDownMenuContent,
	DropDownMenuContainer,
	DropDownMenuTrigger,
	DropDownMenuItem,
	DropDownMenuPortal,
	DropDownMenuArrow,
	DropdownMenuTriggerProps,
	DropdownMenuItemProps,
} from './components/clientComponents/DropdownMenu/DropdownMenu'
import OutsideCloser from './utils/Auxiliar Components/OutsideCloser'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
	InputOTPSeparator,
} from './components/clientComponents/InputOtp'
import { Textarea, ITextarea } from './components/clientComponents/Textarea'
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverAnchor,
} from './components/clientComponents/PopOver'
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from './components/clientComponents/Drawer'
import { Tag, ITag } from './components/clientComponents/Tag'
import {
	Tooltip,
	TooltipTrigger,
	TooltipContent,
	TooltipProvider,
} from './components/clientComponents/Tooltip'
import {
	SidebarContainer,
	SidebarElementContainer,
	SidebarElement,
} from './components/clientComponents/Sidebar'
import {
	Command,
	CommandDialog,
	CommandInput,
	CommandList,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandShortcut,
	CommandSeparator,
} from './components/clientComponents/Command'
import { InputTime } from './components/clientComponents/InputTime'
import {
	InputPhone,
	IPhoneInput,
} from './components/clientComponents/InputPhone'
import {
	CounterBadge,
	ICounterBadge,
} from './components/doctorComponents/CounterBadge'
import { TabButton, ITabButton } from './components/doctorComponents/TabButton'

import { RemoteVideo } from './components/clientComponents/VideoCall/RemoteVideo'
import { LocalVideo } from './components/clientComponents/VideoCall/LocalVideo'
import { ChatList } from './components/clientComponents/Chat/Chat'
import { ChatMessage } from './components/clientComponents/Chat/ChatMessage'
import { Calendar } from './components/clientComponents/Calendar/Calendar'
import { CalendarRange } from './components/clientComponents/Calendar/CalendarRange'
export {
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
	CounterBadge,
	ICounterBadge,
	Icon,
	IIcon,
	ChatList,
	ChatMessage,
	Checkbox,
	ICheckbox,
	Input,
	IInput,
	VisuallyHidden,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
	DialogTitle,
	DialogTrigger,
	IInputFile,
	InputFileItem,
	ISelect,
	SelectItem,
	Select,
	RadioRoot,
	RadioItem,
	BaseRadioItem,
	RadioIndicator,
	DropDownMenuRoot,
	DropDownMenuItem,
	DropDownMenuContainer,
	DropDownMenuContent,
	DropDownMenuPortal,
	DropDownMenuArrow,
	DropDownMenuTrigger,
	OutsideCloser,
	DropdownMenuTriggerProps,
	DropdownMenuItemProps,
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
	InputOTPSeparator,
	Textarea,
	ITextarea,
	IInputOtp,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverAnchor,
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Tag,
	ITag,
	Tooltip,
	TooltipTrigger,
	TooltipContent,
	TooltipProvider,
	SidebarContainer,
	SidebarElementContainer,
	SidebarElement,
	Command,
	CommandDialog,
	CommandInput,
	CommandList,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandShortcut,
	CommandSeparator,
	InputTime,
	InputPhone,
	IPhoneInput,
	TabButton,
	ITabButton,
	RemoteVideo,
	LocalVideo,
	Calendar,
	CalendarRange,
}
